import { Fragment, useContext, useState, useEffect } from 'react';
import { Col, Skeleton, Modal, Button, Spin, Dropdown, Menu } from 'antd';
import { array, bool, object, string, number, any } from 'prop-types';
import { useRouter } from 'next/router';

import { userContext } from 'context/user';
import { i18nContext } from 'context/i18n';
import { ProgressiveBlurredImg } from 'components/base';
import { BellSVG } from 'components/iconsSVG/bell';
import SizesList from 'components/sizesList';

import ProductsModal from 'components/productsModal';

import { parseProductClickEcommerce, parseProductClickEcommerceGA4 } from 'utils/gtmUtils';
import {
  getRealID,
  handleStorage,
  COOKIES,
  getMarket,
  getLang,
  getCookie,
  eraseCookie,
  setCookie,
  obtainVoucherPrices,
  getFixedPrice,
  getIsMobile,
} from 'utils/helpers';

import { GIFT_CARD_CODES, TARGETS_TRANSLATIONS, MAX_BAG_LENGTH } from 'utils/constants/products';
import { AUTO_BONDS } from 'utils/constants/cookies';
import { IMAGE_SIZE_MAPPER } from 'utils/constants/ux';
import { IS_CAMPER, IS_NNORMAL } from 'utils/constants/system';
import { STORES_WITH_SUBTITLE } from 'utils/constants/stores';
import { dataLayerHandleEvent, dataLayerCartClick } from 'utils/dataLayers';
import { setBagCookies } from 'utils/bag';

import { addToBag, getProductLive } from 'actions/products';

import { saveLastViewedProduct } from 'utils/grid';
import styles from './style.module.css';

function Product({
  colXS,
  colMD,
  colLG,
  colXL,
  colXXL,
  locale,
  withHover,
  withLabel,
  imagesHover,
  prices,
  url,
  target,
  sizes,
  images,
  id,
  name,
  colors,
  market,
  item,
  currentList,
  camperlab,
  newShooting,
  withSKU,
  conceptName,
  withDatalayerClick,
  children,
  index,
  webExclusive,
  currentListName,
  membersOnly,
  recalculating,
  voucherApplied,
  hasModal,
  loadingProp,
  squareImg,
  addToBagButton,
  showPrices,
  noLink = false,
  saveLastProduct = false,
}) {
  const router = useRouter();
  const { t, profileData, bagLength, setBagLength, bag, setBag, setPromoLabel, i18nPage } = useContext(i18nContext);
  const { config } = profileData;
  const showFreeShipping = config?.show_free_shipping || false;
  const showGiftPurchase = config?.show_gift_purchase || false;
  const { readyForVouchers, contextFilters, activatedPrevouchers, handleActivatePrevoucher } = useContext(userContext);
  const [hovered, setHover] = useState(false);
  const [hoveredSize, setHoveredSize] = useState(null);
  const [isCalculating, setIsCalculating] = useState(recalculating);
  const isGiftCard = GIFT_CARD_CODES.includes(id);

  // show colors
  const productFamily = getRealID('product', router.query.product);
  const realTargetId = getRealID('target', router.query.target);
  const showColors = IS_CAMPER && productFamily !== '6' && colors && colors.length > 1;

  const [currentPriceData, setCurrentPriceData] = useState(prices !== null && prices !== undefined ? prices : {});
  const [voucherPriceText, setVoucherPriceText] = useState(null);
  const [isVoucherApplied, setIsVoucherApplied] = useState(voucherApplied);
  const [loadingSizesError, setLoadingSizesError] = useState(null);
  const [itemSizes, setItemSizes] = useState(null);
  const [isAddingToBag, setIsAddingToBag] = useState(false);
  const [touristMode, setTouristMode] = useState(false);

  const isMobile = getIsMobile('767.5px');

  const { previous, discount, currentFormated, previousFormated, installments, discountStr } = currentPriceData;

  if (!url) {
    url = `/${locale}/producto?option=${id}`;
  }
  const paths = url && url.split('/');
  if (paths.length > 4 && paths[3].indexOf('_') > -1) {
    url = url.replace(paths[3], locale);
  }
  // const formatedUrl = url ? `/${url.split(`/${locale}/`)[1]}` : '#';
  const formatedUrl = url ? url.substring(url.indexOf(`/${locale}/`) + 6) : '#';
  const isCamperOne = id.endsWith('-999') || id.includes('-999-C');

  const imgBlured = images.sources ? images.sources.filter((source) => source.type === 'xs')[0]?.main : images?.xs?.main || images?.xs;
  const srcSetHover = {};
  const hasSizes = sizes && sizes.length > 0;

  const { storeId } = profileData;
  const isVideo = images.sources && images.sources.length === 1 && images.sources[0].isVideo;
  let videoSource = null;

  if (isVideo) {
    [videoSource] = images.sources;
  }

  if (withHover) {
    if (imagesHover) {
      srcSetHover.xs = imagesHover.xs;
      srcSetHover.md = imagesHover.md;
      srcSetHover.lg = imagesHover.lg;
      srcSetHover.full = imagesHover.full;
    } else if (Array.isArray(images.sources)) {
      images.sources.forEach((source) => {
        if (source.hover) {
          srcSetHover[source.type] = source.hover;
        }
      });
      // for (let i = 0; i < images.sources.length; i++) {
      //   if (images.sources[i].hover) {
      //     srcSetHover[images.sources[i].type] = images.sources[i].hover;
      //   } else {
      //     srcSetHover[images.sources[i].type] = images.sources[i].main;
      //   }
      /* Monta algo asi
        {
          xs: 'url-image-xs.jpg'
          md: 'url-image-md.jpg'
          lg: 'url-image-lg.jpg'
          full: 'url-image-full.jpg'
        }
      */
    }
  }

  useEffect(() => {
    const countryPrice = getCookie(COOKIES.COUNTRY_PRICE);

    if (countryPrice && countryPrice !== market) {
      setTouristMode(true);
    }

    if (readyForVouchers === true) {
      const voucherPriceInfo = obtainVoucherPrices(item, t, locale, contextFilters, activatedPrevouchers, handleActivatePrevoucher);
      if (voucherPriceInfo !== null) {
        setIsCalculating(true);
        const { message, info } = voucherPriceInfo;

        if (message !== null) {
          setVoucherPriceText(message);
        } else {
          const infoPriceData = { ...info };
          // caso raro en el buscador
          if (
            infoPriceData.pricePerSize &&
            !infoPriceData?.priceDiscountFromFormatted &&
            !infoPriceData?.priceDiscountToFormatted &&
            infoPriceData?.voucherRangePrices?.priceFromFormatted
          ) {
            infoPriceData.priceDiscountFromFormatted = infoPriceData?.voucherRangePrices?.priceFromFormatted;
            infoPriceData.priceDiscountToFormatted = infoPriceData?.voucherRangePrices?.priceToFormatted;
            infoPriceData.priceFromFormatted = prices?.priceFromFormatted;
            infoPriceData.priceToFormatted = prices?.priceToFormatted;
            if (prices.usePreviousMinAndMaxPrice) {
              infoPriceData.previousFromFormatted = prices?.previousFromFormatted;
              infoPriceData.previousToFormatted = prices?.previousFromFormatted;
            }
          }
          setCurrentPriceData({ ...infoPriceData, discountFromVoucher: true });
          setIsVoucherApplied(true);
          setVoucherPriceText(null);
        }

        setTimeout(() => {
          setIsCalculating(false);
        }, 1000);
      }
    }
  }, [readyForVouchers]);

  useEffect(() => {
    if (recalculating !== isCalculating) {
      setIsCalculating(recalculating);
    }
  }, [recalculating]);

  const onClickProduct = () => {
    if (withDatalayerClick) {
      const baseEvent = {
        event: 'eventoEC',
        eventCat: 'ecommerce',
        eventLbl: id,
        ecommerce: { click: parseProductClickEcommerce(item, currentList) },
        nonInteraction: 0,
        eventAct: 'productClick',
        isLab: camperlab || false,
      };

      const eventHandler = {
        You_may_also_be_interested: {
          eventAct: 'youMightLikeProductClick',
        },
        Recently_viewed: {
          eventAct: 'recentlyViewedProductClick',
        },
      };

      const handlerParams = eventHandler[currentList];
      dataLayerHandleEvent({
        ...baseEvent,
        ...handlerParams,
      });

      const previousSelectItemData = handleStorage('getItem', 'gtmSelectData');
      let newSelectData = null;

      const previousDataParsed = previousSelectItemData === undefined ? {} : JSON.parse(previousSelectItemData);

      newSelectData = {
        ...previousDataParsed,
        [item.id]: parseProductClickEcommerceGA4(item, index + 1)[0],
      };

      handleStorage('setItem', 'gtmSelectData', JSON.stringify(newSelectData));

      const baseEventGA4 = {
        event: 'select_item',
        ecommerce: { items: parseProductClickEcommerceGA4(item, index + 1) },
        isLab: camperlab || false,
      };
      dataLayerHandleEvent({
        ...baseEventGA4,
        ...handlerParams,
      });
    }
  };

  const handleMouseOver = () => {
    if (!isMobile) {
      setHover(true);
    }
  };

  const handleLoadProductSizes = async () => {
    try {
      const response = await getProductLive({
        lang: locale,
        target: item.targetId,
        productCode: item.id,
      });
      const productSizes = response?.productSheetLive?.sizes;

      setItemSizes(productSizes);
    } catch (e) {
      console.error(e);
      setLoadingSizesError('Error.');
    }
  };

  const handleAddToBagButton = (mode) => {
    if (isMobile || (!isMobile && mode === 'hover')) {
      if (itemSizes === null) {
        handleLoadProductSizes();
      }
    }
  };

  const addToCartHandler = async ({ payload, cookiesBagId, showJustAdded }) => {
    const cookiesMarket = getCookie(COOKIES.BAG_MARKET);
    const cookiesShipment = getCookie(COOKIES.SHIPMENT);
    const cookiesBonds = getCookie(COOKIES.BONDS);
    const cookiesZip = getCookie(COOKIES.ZIP);
    const cookiesEmployeeId = getCookie(COOKIES.EMPLOYEE_ID);
    const cookiesPackstation = getCookie(COOKIES.PACKSTATION);
    const cookiesCtr = getCookie(COOKIES.IS_CTR);
    if (cookiesMarket !== market) {
      payload.hashOrderId = '';
      if (cookiesBagId) {
        eraseCookie(COOKIES.BAG);
        eraseCookie(COOKIES.BAG_DATE);
      }
      if (cookiesZip) {
        eraseCookie(COOKIES.ZIP);
      }
      if (cookiesShipment) {
        eraseCookie(COOKIES.SHIPMENT);
      }
      if (cookiesMarket !== null && cookiesBonds) {
        eraseCookie(COOKIES.BONDS, COOKIES.DOMAIN);
        eraseCookie(COOKIES.AUTO_BONDS);
      }
      if (cookiesEmployeeId) {
        eraseCookie(COOKIES.EMPLOYEE_ID);
      }
      if (cookiesPackstation) {
        eraseCookie(COOKIES.PACKSTATION);
      }
      if (cookiesCtr) {
        eraseCookie(COOKIES.IS_CTR);
      }
    }
    let updatedPromoLabel = null;
    const result = await addToBag(payload);
    const pricesForAdd = Object.keys(currentPriceData)?.length > 0 ? currentPriceData : item?.prices;
    const productSheet = {
      ...item,
      target: item.targetId,
      size: payload.size,
      prices: { ...pricesForAdd, currencySap: item?.prices?.currency },
    };
    dataLayerCartClick({ ...payload, productSheet }, result, true, bag);
    if (result.hashOrderId) {
      setBagCookies({ hash: encodeURI(result.hashOrderId), market });

      const { sapActive, extendedBlockSAP, shoppingCart } = result;
      if (!sapActive && !extendedBlockSAP) {
        // can't pay
        window.location.assign(`${window.location.origin}/${locale}/pedido_offline`);
      } else {
        if (extendedBlockSAP) {
          // can pay
          setCookie(COOKIES.SHIPMENT, '01', undefined, COOKIES.DOMAIN);
        }
        if (!showJustAdded) {
          window.location.assign(`${window.location.origin}/${locale}/bolsa`);
        }
        if (showJustAdded) {
          const { price } = shoppingCart[0]; // siempre es una array con un solo item
          const newBag = bag ? JSON.parse(JSON.stringify(bag)) : [];
          setBag([{ ...result.shoppingCart[0], membersOnly: item?.membersOnly }, ...newBag]);
          setBagLength(bagLength + 1);
          const style = {
            width: !isMobile ? '360px' : '100%',
            maxWidth: !isMobile ? '360px' : '100vw',
            right: '-24px',
          };
          if (isMobile) {
            style.minWidth = 'calc(100vw - 17px)';
          }

          if (showFreeShipping) {
            updatedPromoLabel = result?.freeShippingLabel || null;
          }
          if (showGiftPurchase) {
            updatedPromoLabel = result?.gwplabel || result?.freeShippingLabel || null;
          }
          let priceDiscountString = '';
          if (discountStr || price?.discount) {
            priceDiscountString = discountStr || `-${price.discount}%`;
          }
          const priceDiscountSplitted = priceDiscountString?.split('-');
          if (priceDiscountSplitted?.length > 2) {
            const priceText = priceDiscountSplitted.pop();
            priceDiscountString = `-${priceText}`;
          }

          setPromoLabel(updatedPromoLabel);
        }
      }
    } else {
      // eslint-disable-next-line no-alert
      alert('No se puede procesar el pedido en este momento');
    }
  };

  const handleClickAddToCartBtn = async (showJustAdded = true, selectedSizeValue, selectedSizeLabel) => {
    /* const isUserIdentified = getCookie(COOKIES.SELLIGENT_USRHASH) || userIdentified || bagHasMembersOnlyProduct || userData !== null; */
    setIsAddingToBag(true);
    if (bagLength >= MAX_BAG_LENGTH) {
      Modal.error({
        content: t('ficha.producto', 'alcanzado.limite.bolsa', 'The product could not be added. You have reached the limit of products in your shopping cart.'),
      });
    } else {
      const cookiesBagId = getCookie(COOKIES.BAG);
      const autoVoucher = getCookie(COOKIES.AUTO_BONDS) || '';
      const voucher = getCookie(COOKIES.BONDS) || '';
      const voucherDiscount = IS_CAMPER && autoVoucher && autoVoucher === AUTO_BONDS.APPLY;
      const pricesForAdd = Object.keys(currentPriceData)?.length > 0 ? currentPriceData : item?.prices;
      const payload = {
        id,
        price: pricesForAdd,
        profile: {
          countryId: getMarket(locale),
          languageId: getLang(locale),
        },
        size: selectedSizeValue,
        sizeLabel: selectedSizeLabel,
        gender: target || '',
        thumbnail: item?.imageBag || '', // en ocasiones queda como null y causa un error
      };
      if (voucherDiscount) {
        payload.voucher = voucher;
      }
      if (cookiesBagId) {
        payload.hashOrderId = decodeURI(cookiesBagId);
      }

      await addToCartHandler({
        payload,
        cookiesBagId,
        showJustAdded,
        sizeLabel: selectedSizeLabel,
      });
      setIsAddingToBag(false);
    }
  };

  const onSelectSize = (event, sizeValue, sizeLabel) => {
    /* const isSock = productSheet.sizeType === SOCKS_TYPE; */
    if (IS_CAMPER && !isCamperOne && !membersOnly && !isGiftCard) {
      // ya que esta dentro de un <a href> tenemos que parar el evento para que no nos lleve a la PDP
      event.preventDefault();
      event.stopPropagation();

      const itemCurrentListId = currentList || '';
      const itemCurrentList = itemCurrentListId && currentListName ? `${itemCurrentListId}|${currentListName}` : itemCurrentListId;
      const currentUrl = window?.location?.href?.split('?')[0] || '';

      handleStorage('removeItem', 'gtmCurrentList');
      handleStorage('removeItem', 'gtmCurrentListUrl');

      handleStorage('setItem', 'gtmCurrentList', itemCurrentList ? itemCurrentList.toLowerCase().replaceAll('-', ' ').replaceAll('_', ' ') : '');
      handleStorage('setItem', 'gtmCurrentListUrl', currentUrl);

      handleClickAddToCartBtn(true, sizeValue, sizeLabel);
    }
  };

  const SizeComponent = () => {
    let componentToReturn = null;

    if (hasSizes) {
      const outOfStock = sizes.every((size) => size.available === false) || item?.notifyMeType === 'OUT';
      const isNotifyMe = item.notifyMe === true;
      const isNotifyMeBySize = item.notifyMeType === 'SIZE';
      const reduceSize = squareImg;
      const manySizes = item.targetId === 'K';

      componentToReturn = (
        <>
          <div className="test-grid-b">
            <div
              className={`${styles.sizesWrapper} ${hovered || item?.notifyMeType === 'OUT' ? styles.sizesWrapperVisible : ''} ${outOfStock ? styles.noGridDisplay : ''}`}
            >
              <span className={styles.addToCart}>{t('ficha.producto', 'add.to.bag')}</span>
              <div
                className={`${styles.sizes} ${hovered ? styles.sizesVisible : ''} ${outOfStock ? styles.noGridDisplay : ''} ${reduceSize ? styles.reducedSize : ''} ${manySizes ? styles.manySizes : ''}`}
              >
                {outOfStock ?
                  <span>{`${item.notifyMe && item.notifyMeType === 'PRE' ? t('ficha.producto', 'producto.sin.stock.pre', 'Coming soon') : t('ficha.producto', 'producto.sin.stock.out', 'Out of Stock')}`}</span>
                : sizes.map((size, idx) => {
                    const shouldShowBell = (isNotifyMe && !isNotifyMeBySize) || (isNotifyMe && isNotifyMeBySize && size.available === false);
                    if ((size.available && size.quantity && size.quantity >= 1) || shouldShowBell) {
                      return (
                        <div
                          key={`${id}.${size?.ean || idx}.a`}
                          className={`${styles.sizeNumber} ${shouldShowBell ? styles.withBell : ''}`}
                          onMouseOver={() => setHoveredSize(size.value)}
                          onMouseLeave={() => setHoveredSize(null)}
                          onClick={(event) => onSelectSize(event, size.value, size.label)}
                        >
                          {shouldShowBell && <BellSVG className={styles.bell} backgroundFill="none" />}
                          {size.label}
                        </div>
                      );
                    }
                    return (
                      <span key={`${id}.${size?.ean || idx}.a`} className={`${styles.sizeNumber} ${styles.sizeNotAvailable}`}>
                        {size.label}
                      </span>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className="test-grid-a">
            <div
              className={`${styles.sizes} ${hovered || item?.notifyMeType === 'OUT' ? styles.sizesVisible : ''} ${outOfStock ? styles.noGridDisplay : ''} ${reduceSize ? styles.reducedSize : ''} ${manySizes ? styles.manySizes : ''}`}
            >
              {outOfStock ?
                <span>{`${item.notifyMe && item.notifyMeType === 'PRE' ? t('ficha.producto', 'producto.sin.stock.pre', 'Coming soon') : t('ficha.producto', 'producto.sin.stock.out', 'Out of Stock')}`}</span>
              : sizes.map((size, idx) => {
                  const shouldShowBell = (isNotifyMe && !isNotifyMeBySize) || (isNotifyMe && isNotifyMeBySize && size.available === false);
                  if ((size.available && size.quantity && size.quantity >= 1) || shouldShowBell) {
                    return (
                      <div
                        key={`${id}.${size?.ean || idx}.b`}
                        className={`${styles.sizeNumber} ${shouldShowBell ? styles.withBell : ''}`}
                        onMouseOver={() => setHoveredSize(size.value)}
                        onMouseLeave={() => setHoveredSize(null)}
                      >
                        {shouldShowBell && <BellSVG className={styles.bell} backgroundFill="none" />}
                        {size.label}
                      </div>
                    );
                  }
                  return (
                    <span key={`${id}.${size?.ean || idx}.b`} className={`${styles.sizeNumber} ${styles.sizeNotAvailable}`}>
                      {size.label}
                    </span>
                  );
                })
              }
            </div>
          </div>
        </>
      );
    }
    return componentToReturn;
  };

  const hasBadge = [undefined, ''].includes(item?.badge) === false;

  /*
    lo hacemos así por el cambio de badge de api
    para la respuesta de unbxd solo mostramos el de camper one y el de target
    ya que no recibimos badge
  */

  let customMessage = null;

  if (withLabel === true) {
    if (hasBadge) {
      customMessage = item.badge;
    } else if (isCamperOne) {
      customMessage = t('listado.productos', 'producto.personalizar', 'Customize it');
    } else if (target && target !== realTargetId) {
      customMessage = t(...TARGETS_TRANSLATIONS[target]);
    }
  }

  const priceRange =
    currentPriceData?.priceDiscountFromFormatted && currentPriceData?.priceDiscountToFormatted ?
      `${currentPriceData.priceDiscountFromFormatted} - ${currentPriceData.priceDiscountToFormatted}`
    : `${currentPriceData?.priceFromFormatted} - ${currentPriceData?.priceToFormatted}`;
  const alt =
    images?.sources?.length && images?.sources[0]?.altText ? images?.sources[0]?.altText : `${id} - ${name}${item?.subtitleH1 ? ` - ${item.subtitleH1}` : ''}`;
  const altHover =
    images?.sources?.length && images?.sources[0]?.altTextHover ?
      images?.sources[0]?.altTextHover
    : `${id} - ${name}${item?.subtitleH1 ? ` - ${item.subtitleH1}` : ''}`;
  const itemSubtitle = IS_CAMPER ? item?.subtitleH1 : item?.codeFeatures;

  let priceDiscountString = '';
  if (discountStr || discount) {
    priceDiscountString = discountStr || `-${discount}%`;
  }
  const priceDiscountSplitted = priceDiscountString?.split('-');
  if (priceDiscountSplitted?.length > 2) {
    const priceText = priceDiscountSplitted.pop();
    priceDiscountString = `-${priceText}`;
  }
  if (!priceDiscountString?.startsWith('-')) {
    priceDiscountString = `-${priceDiscountString}`;
  }

  const baseSpinner = <Spin spinning className={styles.sizeSpinner} />;
  let SizeContent = <Spin spinning className={styles.sizeSpinner} />;

  if (loadingSizesError) {
    SizeContent = <p>{loadingSizesError}</p>;
  } else if (itemSizes !== null) {
    SizeContent = (
      <SizesList
        index={index}
        sizes={itemSizes}
        onChange={async (size, sizeLabel) => {
          await handleClickAddToCartBtn('recommended', size, sizeLabel);
        }}
        grid={{
          gutter: 0,
          column: itemSizes?.length ?? 1,
        }}
        locale={locale}
        calculateColumns
      />
    );
  }

  const fixedPrice = getFixedPrice(market, profileData, currentPriceData?.current);
  let ProductElement = 'a';

  if (showPrices === false) {
    ProductElement = 'div';
  }

  return (
    <Col
      xs={colXS}
      md={colMD}
      lg={colLG}
      xl={colXL}
      xxl={colXXL}
      onMouseDown={() => saveLastProduct && saveLastViewedProduct(id)}
      onMouseUp={onClickProduct}
      className="grid-item"
    >
      <div className={styles.productCard} data-product-id={id}>
        <ProductElement
          onMouseOver={handleMouseOver}
          onMouseLeave={() => setHover(false)}
          href={
            noLink ? undefined
            : !hoveredSize ?
              `/${locale}${formatedUrl}`
            : `/${locale}${formatedUrl}?size=${hoveredSize}`
          }
        >
          <div className={newShooting ? styles.gridPhoto : `${styles.gridPhoto} ${styles.oldShooting} oldShooting`}>
            <div className="specialProductMessage">
              {customMessage !== null && (
                <Fragment key={`customMessage-${id}`}>
                  <div className={styles.customizeContainer}>
                    <p>{customMessage}</p>
                  </div>
                </Fragment>
              )}
            </div>
            <div className={`${styles.gridImages} gridImages`} data-square={squareImg}>
              {isVideo ?
                <video
                  className={styles.gridVideo}
                  autoPlay
                  loop
                  title={`Video of ${id}`}
                  muted
                  playsInline
                  poster={videoSource.poster}
                  dangerouslySetInnerHTML={{ __html: videoSource.sources }}
                ></video>
              : <ProgressiveBlurredImg
                  lowQualitySrc={imgBlured}
                  highQualitySrc={images.sources ? images.sources : images}
                  initLoaded={true}
                  alt={alt}
                  index={index}
                  loading={loadingProp}
                />
              }
              {!squareImg && withHover && (
                <picture className={hovered ? `${styles.imageHover} ${styles.visible}` : styles.imageHover}>
                  {/* TO DO: En el futuro, hacer lo mismo que hacemos en el source de webp para image/avif */}
                  {Object.keys(srcSetHover)
                    .filter((hoverItem) => hoverItem !== 'xs')
                    .map((srcKey) => (
                      <source
                        key={`${id}-${srcKey}`}
                        type="image/avif"
                        srcSet={`
                            ${srcSetHover[srcKey]?.replace('.jpg', '.avif')}
                          `}
                        media={IMAGE_SIZE_MAPPER[srcKey]}
                      />
                    ))}
                  {Object.keys(srcSetHover)
                    .filter((hoverItem) => hoverItem !== 'xs')
                    .map((srcKey) => (
                      <source
                        key={`${id}-${srcKey}`}
                        type="image/webp"
                        srcSet={`
                            ${srcSetHover[srcKey]?.replace('.jpg', '.webp')}
                          `}
                        media={IMAGE_SIZE_MAPPER[srcKey]}
                      />
                    ))}
                  {Object.keys(srcSetHover)
                    .filter((hoverItem) => hoverItem !== 'xs')
                    .map((srcKey) => (
                      <source
                        key={`${id}-${srcKey}`}
                        type="image/jpg"
                        srcSet={`
                          ${srcSetHover[srcKey]}
                        `}
                        media={IMAGE_SIZE_MAPPER[srcKey]}
                      />
                    ))}
                  <img src={srcSetHover.md} alt={altHover} className={hovered ? `${styles.imageHover} ${styles.visible}` : styles.imageHover} loading="eager" />
                </picture>
              )}
            </div>
            {IS_CAMPER && !addToBagButton ?
              <SizeComponent />
            : null}
            {IS_NNORMAL && webExclusive ?
              <p className={styles.exclusive}>{t('listado.productos', 'web.exclusive', 'exclusive nnormal.com')}</p>
            : null}
          </div>
          <div className={`${styles.gridContent} gridContent`}>
            <p className={`${styles.name} name`}>{name || conceptName}</p>
            {itemSubtitle && (IS_NNORMAL || STORES_WITH_SUBTITLE.includes(storeId)) && !item.recycled && (
              <p className={styles.productSubtitle}>{itemSubtitle}</p>
            )}
            {withSKU && <p className={styles.sku}>{id}</p>}
            {!GIFT_CARD_CODES.includes(id) && showPrices && touristMode === false && (
              <>
                {isCalculating && <Skeleton.Input active loading size="small" className={styles.recalcPrice} />}
                {!isCalculating && (
                  <p data-fp={fixedPrice} data-ge-price="data-ge-price" className={styles.price}>
                    {currentPriceData.pricePerSize ? priceRange : currentFormated}
                  </p>
                )}
                {isCalculating ?
                  <Skeleton.Input active loading size="small" className={styles.recalcPrice} />
                : <>
                    {previous !== undefined && (
                      <p className={`${styles.discountText} discountText`}>
                        <s>
                          {currentPriceData.pricePerSize ?
                            `${currentPriceData.previousFromFormatted || currentPriceData.priceFromFormatted} - ${currentPriceData.previousToFormatted || currentPriceData.priceToFormatted}`
                          : previousFormated}
                        </s>
                        <span
                          className={
                            isVoucherApplied && currentPriceData?.discountFromVoucher ?
                              `${styles.discount} ${styles.discountByVoucher} green`
                            : `${styles.discount} green`
                          }
                        >
                          {priceDiscountString}
                        </span>
                      </p>
                    )}
                    {previous !== undefined && isVoucherApplied && currentPriceData?.discountFromVoucher && voucherPriceText === null && (
                      <p className={styles.voucherApplied}>{currentPriceData.appliedLabel}</p>
                    )}
                    {![null, undefined].includes(voucherPriceText) && (
                      <p className={`${styles.voucherPrice}`} dangerouslySetInnerHTML={{ __html: voucherPriceText }} />
                    )}
                  </>
                }
                {currentPriceData?.pricePerSize && (
                  <p className={styles.pricePerSize}>{t('listado.productos', 'final.price.acording', 'Final price acording to size')}</p>
                )}
                {installments && <p className={styles.installments}>{`${t('listado.productos', 'plazos.pago')} ${installments}`}</p>}
              </>
            )}
          </div>
        </ProductElement>
        <p className={`${styles.itemInfo} itemInfo`}>
          {showColors && i18nPage !== 'Home' && (
            <span>
              {colors.length} {t('menu.lateral', 'colores')}
            </span>
          )}
          {hasModal && <ProductsModal.Button productSheet={item} origin="grid" realTarget={realTargetId} />}
        </p>
        {addToBagButton && (
          <>
            {item?.sizeType !== undefined ?
              <Dropdown
                trigger={[isMobile ? 'click' : 'hover']}
                align={{ overflow: { adjustX: isMobile, adjustY: false } }}
                overlay={<Menu className={styles.sizesMenu}>{isAddingToBag ? baseSpinner : SizeContent}</Menu>}
                placement="topCenter"
                arrow={{ pointAtCenter: true }}
                overlayClassName={styles.sizesDropdown}
              >
                <Button onMouseEnter={() => handleAddToBagButton('hover')} onClick={handleAddToBagButton} className={styles.addToBagRecommended}>
                  {t('ficha.producto', 'add.to.bag')}
                </Button>
              </Dropdown>
            : <>
                {isAddingToBag ?
                  baseSpinner
                : <Button onClick={() => handleClickAddToCartBtn('recommended', ' ', ' ')} className={styles.addToBagRecommended}>
                    {t('ficha.producto', 'add.to.bag')}
                  </Button>
                }
              </>
            }
          </>
        )}
      </div>
      {children}
    </Col>
  );
}

Product.propTypes = {
  item: object,
  loadingProp: any,
  colXS: object,
  colMD: object,
  colLG: object,
  colXL: object,
  colXXL: object,
  labels: array,
  target: string,
  product: string,
  locale: string,
  withHover: bool,
  withLabel: bool,
  infiniteScrollLoading: bool,
  defaultLimit: number,
  id: string,
  name: string,
  conceptName: string,
  prices: object,
  images: object,
  sizes: array,
  family: string,
  colors: array,
  url: string,
  sustainabilities: array,
  market: string,
  isLastProduct: bool,
  imagesHover: object,
  currentList: string,
  currentListName: string,
  camperlab: bool,
  newShooting: bool,
  withSKU: bool,
  withDatalayerClick: bool,
  children: any,
  index: number,
  membersOnly: bool,
  webExclusive: bool,
  recalculating: bool,
  voucherApplied: bool,
  hasModal: bool,
  squareImg: bool,
  addToBagButton: bool,
  showPrices: bool,
  noLink: bool,
};

Product.defaultProps = {
  item: {},
  loadingProp: 'lazy',
  colXS: { span: 12 },
  colMD: { span: 8 },
  colLG: { span: 8 },
  colXL: { span: 8 },
  colXXL: { span: 6 },
  labels: [],
  withHover: true,
  withLabel: false,
  infiniteScrollLoading: false,
  imagesHover: null,
  camperlab: false,
  newShooting: false,
  withSKU: false,
  withDatalayerClick: true,
  membersOnly: false,
  webExclusive: false,
  recalculating: false,
  voucherApplied: false,
  hasModal: false,
  squareImg: false,
  addToBagButton: false,
  showPrices: true,
};

export default Product;
